/**
 * @module SaveService
 * @description SaveService handles save button loading state and updates timestamp.
 * @example timestamp examples: https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/02-fromnow/
 */
import moment from 'moment';

import Subscriber from '@libs/Subscriber';
import Filters from '@master/Filters';

class SaveService extends Subscriber {
  #saving = false;
  #timestamp = null;
  #unix = null;

  startSaving() {
    this.#saving = true;
    this.#change();
  }

  stopSaving() {
    this.#saving = false;
    this.#change();
  }

  setTimestamp(unix) {
    unix = unix ?? moment().unix();
    this.#unix = unix;
    this.#timestamp = Filters.TimeDifferenceFilter(unix);
    this.#change();
  }

  getTimestamp() {
    return this.#timestamp;
  }

  getUnix() {
    return this.#unix;
  }

  #change() {
    this.data = {
      saving: this.#saving,
      timestamp: this.#timestamp,
    };
  }
}

export default {
  creative: new SaveService(),
  flight: new SaveService(),
};
